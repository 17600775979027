<template>
  <div id="addAssignmentModal" class="modal is-active" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header content">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="myModalLabel" class="modal-title">
            Choose List
          </h4>
        </div>

        <div class="modal-body" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChooseFavouriteList',
  props: ['pupil'],
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
