<template>
  <section>
    <div v-if="numberGameType == 'numberBonds'" @input="updateValue($event.target.value)">
      <select v-model="key" class="form-control">
        <option value="" selected :disabled="!allowNull">
          -- Choose a game --
        </option>
        <option value="numberBonds_10_add">
          Bonds to 10 - Add
        </option>
        <option value="numberBonds_10_subtract">
          Bonds to 10 - Subtract
        </option>
        <option value="numberBonds_10_mixed">
          Bonds to 10 - Mixed
        </option>
        <option value="numberBonds_20_add">
          Bonds to 20 - Add
        </option>
        <option value="numberBonds_20_subtract">
          Bonds to 20 - Subtract
        </option>
        <option value="numberBonds_20_mixed">
          Bonds to 20 - Mixed
        </option>
        <option value="numberBonds_100_add">
          Bonds to 100 - Add
        </option>
        <option value="numberBonds_100_subtract">
          Bonds to 100 - Subtract
        </option>
        <option value="numberBonds_100_mixed">
          Bonds to 100 - Mixed
        </option>
      </select>
    </div>
    <div v-if="numberGameType == 'timesTables'" @input="updateValue($event.target.value)">
      <select v-model="key" class="form-control">
        <option value="" selected :disabled="!allowNull">
          -- Choose a game --
        </option>
        <optgroup label="Mixed">
          <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_times">
            2 - 12s, Multiply
          </option>
          <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_divide">
            2 - 12s, Divide
          </option>
          <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_mixed">
            2 - 12s, Mixed
          </option>
          <option value="timesTables_2,5,10_times">
            2s, 5s & 10s, Multiply
          </option>
          <option value="timesTables_2,5,10_divide">
            2s, 5s & 10s, Divide
          </option>
          <option value="timesTables_2,5,10_mixed">
            2s, 5s & 10s, Mixed
          </option>
          <option value="timesTables_3,4,8_times">
            3s, 4s & 8s, Multiply
          </option>
          <option value="timesTables_3,4,8_divide">
            3s, 4s & 8s, Divide
          </option>
          <option value="timesTables_3,4,8_mixed">
            3s, 4s & 8s, Mixed
          </option>
          <option value="timesTables_2,3,4,5,8,10_times">
            2s, 3s, 4s, 5s, 8s & 10s, Multiply
          </option>
          <option value="timesTables_2,3,4,5,8,10_divide">
            2s, 3s, 4s, 5s, 8s & 10s, Divide
          </option>
          <option value="timesTables_2,3,4,5,8,10_mixed">
            2s, 3s, 4s, 5s, 8s & 10s, Mixed
          </option>
          <option value="timesTables_3,6,9_times">
            3s, 6s & 9s, Multiply
          </option>
          <option value="timesTables_3,6,9_divide">
            3s, 6s & 9s, Divide
          </option>
          <option value="timesTables_3,6,9_mixed">
            3s, 6s & 9s, Mixed
          </option>
          <option value="timesTables_4,8,12_times">
            4s, 8s & 12s, Multiply
          </option>
          <option value="timesTables_4,8,12_divide">
            4s, 8s & 12s, Divide
          </option>
          <option value="timesTables_4,8,12_mixed">
            4s, 8s & 12s, Mixed
          </option>
          <option value="timesTables_4,6,7,8_times">
            4s, 6s, 7s & 8s, Multiply
          </option>
          <option value="timesTables_4,6,7,8_divide">
            4s, 6s, 7s & 8s, Divide
          </option>
          <option value="timesTables_4,6,7,8_mixed">
            4s, 6s, 7s & 8s, Mixed
          </option>
          <option value="timesTables_11,12_times">
            11s & 12s, Multiply
          </option>
          <option value="timesTables_11,12_divide">
            11s & 12s, Divide
          </option>
          <option value="timesTables_11,12_mixed">
            11s & 12s, Mixed
          </option>
        </optgroup>
        <optgroup label="Single">
          <option value="timesTables_2_times">
            2s, Multiply
          </option>
          <option value="timesTables_2_divide">
            2s, Divide
          </option>
          <option value="timesTables_2_mixed">
            2s, Mixed
          </option>
          <option value="timesTables_3_times">
            3s, Multiply
          </option>
          <option value="timesTables_3_divide">
            3s, Divide
          </option>
          <option value="timesTables_3_mixed">
            3s, Mixed
          </option>
          <option value="timesTables_4_times">
            4s, Multiply
          </option>
          <option value="timesTables_4_divide">
            4s, Divide
          </option>
          <option value="timesTables_4_mixed">
            4s, Mixed
          </option>
          <option value="timesTables_5_times">
            5s, Multiply
          </option>
          <option value="timesTables_5_divide">
            5s, Divide
          </option>
          <option value="timesTables_5_mixed">
            5s, Mixed
          </option>
          <option value="timesTables_6_times">
            6s, Multiply
          </option>
          <option value="timesTables_6_divide">
            6s, Divide
          </option>
          <option value="timesTables_6_mixed">
            6s, Mixed
          </option>
          <option value="timesTables_7_times">
            7s, Multiply
          </option>
          <option value="timesTables_7_divide">
            7s, Divide
          </option>
          <option value="timesTables_7_mixed">
            7s, Mixed
          </option>
          <option value="timesTables_8_times">
            8s, Multiply
          </option>
          <option value="timesTables_8_divide">
            8s, Divide
          </option>
          <option value="timesTables_8_mixed">
            8s, Mixed
          </option>
          <option value="timesTables_9_times">
            9s, Multiply
          </option>
          <option value="timesTables_9_divide">
            9s, Divide
          </option>
          <option value="timesTables_9_mixed">
            9s, Mixed
          </option>
          <option value="timesTables_10_times">
            10s, Multiply
          </option>
          <option value="timesTables_10_divide">
            10s, Divide
          </option>
          <option value="timesTables_10_mixed">
            10s, Mixed
          </option>
          <option value="timesTables_11_times">
            11s, Multiply
          </option>
          <option value="timesTables_11_divide">
            11s, Divide
          </option>
          <option value="timesTables_11_mixed">
            11s, Mixed
          </option>
          <option value="timesTables_12_times">
            12s, Multiply
          </option>
          <option value="timesTables_12_divide">
            12s, Divide
          </option>
          <option value="timesTables_12_mixed">
            12s, Mixed
          </option>
        </optgroup>
        <optgroup label="Related Facts">
          <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_times_related">
            2 - 12s, Multiply
          </option>
          <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_divide_related">
            2 - 12s, Divide
          </option>
          <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_mixed_related">
            2 - 12s, Mixed
          </option>
          <option value="timesTables_2,5,10_times_related">
            2s, 5s & 10s, Multiply
          </option>
          <option value="timesTables_2,5,10_divide_related">
            2s, 5s & 10s, Divide
          </option>
          <option value="timesTables_2,5,10_mixed_related">
            2s, 5s & 10s, Mixed
          </option>
          <option value="timesTables_3,4,8_times_related">
            3s, 4s & 8s, Multiply
          </option>
          <option value="timesTables_3,4,8_divide_related">
            3s, 4s & 8s, Divide
          </option>
          <option value="timesTables_3,4,8_mixed_related">
            3s, 4s & 8s, Mixed
          </option>
          <option value="timesTables_2,3,4,5,8,10_times_related">
            2s, 3s, 4s, 5s, 8s & 10s, Multiply
          </option>
          <option value="timesTables_2,3,4,5,8,10_divide_related">
            2s, 3s, 4s, 5s, 8s & 10s, Divide
          </option>
          <option value="timesTables_2,3,4,5,8,10_mixed_related">
            2s, 3s, 4s, 5s, 8s & 10s, Mixed
          </option>
          <option value="timesTables_3,6,9_times_related">
            3s, 6s & 9s, Multiply
          </option>
          <option value="timesTables_3,6,9_divide_related">
            3s, 6s & 9s, Divide
          </option>
          <option value="timesTables_3,6,9_mixed_related">
            3s, 6s & 9s, Mixed
          </option>
          <option value="timesTables_4,8,12_times_related">
            4s, 8s & 12s, Multiply
          </option>
          <option value="timesTables_4,8,12_divide_related">
            4s, 8s & 12s, Divide
          </option>
          <option value="timesTables_4,8,12_mixed_related">
            4s, 8s & 12s, Mixed
          </option>
          <option value="timesTables_4,6,7,8_times_related">
            4s, 6s, 7s & 8s, Multiply
          </option>
          <option value="timesTables_4,6,7,8_divide_related">
            4s, 6s, 7s & 8s, Divide
          </option>
          <option value="timesTables_4,6,7,8_mixed_related">
            4s, 6s, 7s & 8s, Mixed
          </option>
          <option value="timesTables_11,12_times_related">
            11s & 12s, Multiply
          </option>
          <option value="timesTables_11,12_divide_related">
            11s & 12s, Divide
          </option>
          <option value="timesTables_11,12_mixed_related">
            11s & 12s, Mixed
          </option>
          <option value="timesTables_2_times_related">
            2s, Multiply
          </option>
          <option value="timesTables_2_divide_related">
            2s, Divide
          </option>
          <option value="timesTables_2_mixed_related">
            2s, Mixed
          </option>
          <option value="timesTables_3_times_related">
            3s, Multiply
          </option>
          <option value="timesTables_3_divide_related">
            3s, Divide
          </option>
          <option value="timesTables_3_mixed_related">
            3s, Mixed
          </option>
          <option value="timesTables_4_times_related">
            4s, Multiply
          </option>
          <option value="timesTables_4_divide_related">
            4s, Divide
          </option>
          <option value="timesTables_4_mixed_related">
            4s, Mixed
          </option>
          <option value="timesTables_5_times_related">
            5s, Multiply
          </option>
          <option value="timesTables_5_divide_related">
            5s, Divide
          </option>
          <option value="timesTables_5_mixed_related">
            5s, Mixed
          </option>
          <option value="timesTables_6_times_related">
            6s, Multiply
          </option>
          <option value="timesTables_6_divide_related">
            6s, Divide
          </option>
          <option value="timesTables_6_mixed_related">
            6s, Mixed
          </option>
          <option value="timesTables_7_times_related">
            7s, Multiply
          </option>
          <option value="timesTables_7_divide_related">
            7s, Divide
          </option>
          <option value="timesTables_7_mixed_related">
            7s, Mixed
          </option>
          <option value="timesTables_8_times_related">
            8s, Multiply
          </option>
          <option value="timesTables_8_divide_related">
            8s, Divide
          </option>
          <option value="timesTables_8_mixed_related">
            8s, Mixed
          </option>
          <option value="timesTables_9_times_related">
            9s, Multiply
          </option>
          <option value="timesTables_9_divide_related">
            9s, Divide
          </option>
          <option value="timesTables_9_mixed_related">
            9s, Mixed
          </option>
          <option value="timesTables_10_times_related">
            10s, Multiply
          </option>
          <option value="timesTables_10_divide_related">
            10s, Divide
          </option>
          <option value="timesTables_10_mixed_related">
            10s, Mixed
          </option>
          <option value="timesTables_11_times_related">
            11s, Multiply
          </option>
          <option value="timesTables_11_divide_related">
            11s, Divide
          </option>
          <option value="timesTables_11_mixed_related">
            11s, Mixed
          </option>
          <option value="timesTables_12_times_related">
            12s, Multiply
          </option>
          <option value="timesTables_12_divide_related">
            12s, Divide
          </option>
          <option value="timesTables_12_mixed_related">
            12s, Mixed
          </option>
        </optgroup>
        <optgroup label="Related Facts Including Decimals">
          <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_times_relateddecimals">
            2 - 12s, Multiply
          </option>
          <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_divide_relateddecimals">
            2 - 12s, Divide
          </option>
          <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_mixed_relateddecimals">
            2 - 12s, Mixed
          </option>
          <option value="timesTables_2,5,10_times_relateddecimals">
            2s, 5s & 10s, Multiply
          </option>
          <option value="timesTables_2,5,10_divide_relateddecimals">
            2s, 5s & 10s, Divide
          </option>
          <option value="timesTables_2,5,10_mixed_relateddecimals">
            2s, 5s & 10s, Mixed
          </option>
          <option value="timesTables_3,4,8_times_relateddecimals">
            3s, 4s & 8s, Multiply
          </option>
          <option value="timesTables_3,4,8_divide_relateddecimals">
            3s, 4s & 8s, Divide
          </option>
          <option value="timesTables_3,4,8_mixed_relateddecimals">
            3s, 4s & 8s, Mixed
          </option>
          <option value="timesTables_2,3,4,5,8,10_times_relateddecimals">
            2s, 3s, 4s, 5s, 8s & 10s, Multiply
          </option>
          <option value="timesTables_2,3,4,5,8,10_divide_relateddecimals">
            2s, 3s, 4s, 5s, 8s & 10s, Divide
          </option>
          <option value="timesTables_2,3,4,5,8,10_mixed_relateddecimals">
            2s, 3s, 4s, 5s, 8s & 10s, Mixed
          </option>
          <option value="timesTables_3,6,9_times_relateddecimals">
            3s, 6s & 9s, Multiply
          </option>
          <option value="timesTables_3,6,9_divide_relateddecimals">
            3s, 6s & 9s, Divide
          </option>
          <option value="timesTables_3,6,9_mixed_relateddecimals">
            3s, 6s & 9s, Mixed
          </option>
          <option value="timesTables_4,8,12_times_relateddecimals">
            4s, 8s & 12s, Multiply
          </option>
          <option value="timesTables_4,8,12_divide_relateddecimals">
            4s, 8s & 12s, Divide
          </option>
          <option value="timesTables_4,8,12_mixed_relateddecimals">
            4s, 8s & 12s, Mixed
          </option>
          <option value="timesTables_4,6,7,8_times_relateddecimals">
            4s, 6s, 7s & 8s, Multiply
          </option>
          <option value="timesTables_4,6,7,8_divide_relateddecimals">
            4s, 6s, 7s & 8s, Divide
          </option>
          <option value="timesTables_4,6,7,8_mixed_relateddecimals">
            4s, 6s, 7s & 8s, Mixed
          </option>
          <option value="timesTables_11,12_times_relateddecimals">
            11s & 12s, Multiply
          </option>
          <option value="timesTables_11,12_divide_relateddecimals">
            11s & 12s, Divide
          </option>
          <option value="timesTables_11,12_mixed_relateddecimals">
            11s & 12s, Mixed
          </option>
          <option value="timesTables_2_times_relateddecimals">
            2s, Multiply
          </option>
          <option value="timesTables_2_divide_relateddecimals">
            2s, Divide
          </option>
          <option value="timesTables_2_mixed_relateddecimals">
            2s, Mixed
          </option>
          <option value="timesTables_3_times_relateddecimals">
            3s, Multiply
          </option>
          <option value="timesTables_3_divide_relateddecimals">
            3s, Divide
          </option>
          <option value="timesTables_3_mixed_relateddecimals">
            3s, Mixed
          </option>
          <option value="timesTables_4_times_relateddecimals">
            4s, Multiply
          </option>
          <option value="timesTables_4_divide_relateddecimals">
            4s, Divide
          </option>
          <option value="timesTables_4_mixed_relateddecimals">
            4s, Mixed
          </option>
          <option value="timesTables_5_times_relateddecimals">
            5s, Multiply
          </option>
          <option value="timesTables_5_divide_relateddecimals">
            5s, Divide
          </option>
          <option value="timesTables_5_mixed_relateddecimals">
            5s, Mixed
          </option>
          <option value="timesTables_6_times_relateddecimals">
            6s, Multiply
          </option>
          <option value="timesTables_6_divide_relateddecimals">
            6s, Divide
          </option>
          <option value="timesTables_6_mixed_relateddecimals">
            6s, Mixed
          </option>
          <option value="timesTables_7_times_relateddecimals">
            7s, Multiply
          </option>
          <option value="timesTables_7_divide_relateddecimals">
            7s, Divide
          </option>
          <option value="timesTables_7_mixed_relateddecimals">
            7s, Mixed
          </option>
          <option value="timesTables_8_times_relateddecimals">
            8s, Multiply
          </option>
          <option value="timesTables_8_divide_relateddecimals">
            8s, Divide
          </option>
          <option value="timesTables_8_mixed_relateddecimals">
            8s, Mixed
          </option>
          <option value="timesTables_9_times_relateddecimals">
            9s, Multiply
          </option>
          <option value="timesTables_9_divide_relateddecimals">
            9s, Divide
          </option>
          <option value="timesTables_9_mixed_relateddecimals">
            9s, Mixed
          </option>
          <option value="timesTables_10_times_relateddecimals">
            10s, Multiply
          </option>
          <option value="timesTables_10_divide_relateddecimals">
            10s, Divide
          </option>
          <option value="timesTables_10_mixed_relateddecimals">
            10s, Mixed
          </option>
          <option value="timesTables_11_times_relateddecimals">
            11s, Multiply
          </option>
          <option value="timesTables_11_divide_relateddecimals">
            11s, Divide
          </option>
          <option value="timesTables_11_mixed_relateddecimals">
            11s, Mixed
          </option>
          <option value="timesTables_12_times_relateddecimals">
            12s, Multiply
          </option>
          <option value="timesTables_12_divide_relateddecimals">
            12s, Divide
          </option>
          <option value="timesTables_12_mixed_relateddecimals">
            12s, Mixed
          </option>
        </optgroup>
      </select>
    </div>
    <div v-if="numberGameType == 'powerTen'" @input="updateValue($event.target.value)">
      <select v-model="key" class="form-control">
        <option value="" selected :disabled="!allowNull">
          -- Choose a game --
        </option>
        <optgroup label="Whole Numbers">
          <option value="powerTen_10_times">
            10s, Multiply
          </option>
          <option value="powerTen_10_divide">
            10s, Divide
          </option>
          <option value="powerTen_10_mixed">
            10s, Mixed
          </option>
          <option value="powerTen_100_times">
            100s, Multiply
          </option>
          <option value="powerTen_100_divide">
            100s, Divide
          </option>
          <option value="powerTen_100_mixed">
            100s, Mixed
          </option>
          <option value="powerTen_10,100_times">
            10s & 100s, Multiply
          </option>
          <option value="powerTen_10,100_divide">
            10s & 100s, Divide
          </option>
          <option value="powerTen_10,100_mixed">
            10s & 100s, Mixed
          </option>
          <option value="powerTen_1000_times">
            1000s, Multiply
          </option>
          <option value="powerTen_1000_divide">
            1000s, Divide
          </option>
          <option value="powerTen_1000_mixed">
            1000s, Mixed
          </option>
          <option value="powerTen_10,100,1000_times">
            10s, 100s & 1000s, Multiply
          </option>
          <option value="powerTen_10,100,1000_divide">
            10s, 100s & 1000s, Divide
          </option>
          <option value="powerTen_10,100,1000_mixed">
            10s, 100s & 1000s, Mixed
          </option>
        </optgroup>
        <optgroup label="Decimals">
          <option value="powerTenDecimals_10_times">
            10s, Multiply
          </option>
          <option value="powerTenDecimals_10_divide">
            10s, Divide
          </option>
          <option value="powerTenDecimals_10_mixed">
            10s, Mixed
          </option>
          <option value="powerTenDecimals_100_times">
            100s, Multiply
          </option>
          <option value="powerTenDecimals_100_divide">
            100s, Divide
          </option>
          <option value="powerTenDecimals_100_mixed">
            100s, Mixed
          </option>
          <option value="powerTenDecimals_10,100_times">
            10s & 100s, Multiply
          </option>
          <option value="powerTenDecimals_10,100_divide">
            10s & 100s, Divide
          </option>
          <option value="powerTenDecimals_10,100_mixed">
            10s & 100s, Mixed
          </option>
          <option value="powerTenDecimals_1000_times">
            1000s, Multiply
          </option>
          <option value="powerTenDecimals_1000_divide">
            1000s, Divide
          </option>
          <option value="powerTenDecimals_1000_mixed">
            1000s, Mixed
          </option>
          <option value="powerTenDecimals_10,100,1000_times">
            10s, 100s & 1000s, Multiply
          </option>
          <option value="powerTenDecimals_10,100,1000_divide">
            10s, 100s & 1000s, Divide
          </option>
          <option value="powerTenDecimals_10,100,1000_mixed">
            10s, 100s & 1000s, Mixed
          </option>
        </optgroup>
      </select>
    </div>
    <div v-if="numberGameType == 'addSubtract'" @input="updateValue($event.target.value)">
      <select v-model="key" class="form-control">
        <option value="" selected :disabled="!allowNull">
          -- Choose a game --
        </option>
        <option value="addSubtract_10_add_within">
          Within 10, Add
        </option>
        <option value="addSubtract_10_subtract_within">
          Within 10, Subtract
        </option>
        <option value="addSubtract_10_mixed_within">
          Within 10, Mixed
        </option>

        <option value="addSubtract_20_add_within">
          Within 20, Add
        </option>
        <option value="addSubtract_20_subtract_within">
          Within 20, Subtract
        </option>
        <option value="addSubtract_20_mixed_within">
          Within 20, Mixed
        </option>

        <option value="addSubtract_100_add_within">
          Within 100, Add
        </option>
        <option value="addSubtract_100_subtract_within">
          Within 100, Subtract
        </option>
        <option value="addSubtract_100_mixed_within">
          Within 100, Mixed
        </option>

        <option value="addSubtract_99_add">
          2 digits, Add
        </option>
        <option value="addSubtract_99_subtract">
          2 digits, Subtract
        </option>
        <option value="addSubtract_99_mixed">
          2 digits, Mixed
        </option>

        <option value="addSubtract_999_add">
          3 digits, Add
        </option>
        <option value="addSubtract_999_subtract">
          3 digits, Subtract
        </option>
        <option value="addSubtract_999_mixed">
          3 digits, Mixed
        </option>

        <option value="addSubtract_9999_add">
          4 digits, Add
        </option>
        <option value="addSubtract_9999_subtract">
          4 digits, Subtract
        </option>
        <option value="addSubtract_9999_mixed">
          4 digits, Mixed
        </option>
      </select>
    </div>
    <div v-if="numberGameType == 'more'" @input="updateValue($event.target.value)">
      <select v-model="key" class="form-control">
        <option value="" selected :disabled="!allowNull">
          -- Choose a game --
        </option>
        <optgroup label="Year 4 MTC">
          <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_times_mtc">
            Year 4 MTC Simulator
          </option>
        </optgroup>
        <optgroup label="Negative Numbers">
          <option value="more_neg_add">
            Counting through zero, Add
          </option>
          <option value="more_neg_subtract">
            Counting through zero, Subtract
          </option>
          <option value="more_neg_mixed">
            Counting through zero, Mixed
          </option>
        </optgroup>
      </select>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SelectNumberGameType',
  props: {
    value: {
      type: String,
      default: ''
    },
    numberGameType: {
      type: String,
      default: 'numberBonds'
    },
    allowNull: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    key: {
      get () {
        return (this.value)
      },
      set () {

      }
    }
  },
  created () {
    if (this.value != null) {
      this.key = this.value
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>
