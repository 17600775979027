<template>
  <!-- Main content -->
  <div>
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border content">
          <h2>Competence </h2>
        </div>
        <div class="box-body">
          <div id="pupilActivity" class="ct-chart" />
          <form class="form-inline text-center" style="overflow-x: scroll">
            <label>{{ $store.state.user.locale === 'en_US' ? 'Math' : 'Maths' }} Game</label>
            <b-field class="is-fullwidth">
              <b-radio-button v-model="numberGameType" native-value="numberBonds" @input="resetList">
                <span style="font-weight:normal;">Number Bonds</span>
              </b-radio-button>
              <b-radio-button v-model="numberGameType" native-value="timesTables" @input="resetList">
                <span style="font-weight:normal;">Times Tables</span>
              </b-radio-button>
              <b-radio-button v-model="numberGameType" native-value="powerTen" @input="resetList">
                <span style="font-weight:normal;">Powers of 10</span>
              </b-radio-button>
              <b-radio-button v-model="numberGameType" native-value="addSubtract" @input="resetList">
                <span style="font-weight:normal;">Add &amp; Subtract</span>
              </b-radio-button>
              <b-radio-button v-model="numberGameType" native-value="more" @input="resetList">
                <span style="font-weight:normal;">More</span>
              </b-radio-button>
            </b-field>
            <SelectNumberGameType v-model="list" :number-game-type-prop="numberGameType" @input="didChange" />
          </form>
          <h4>Average time per correct answer (s)</h4>
          <div id="chart1" class="ct-chart" />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="box box-success">
        <div class="box-header with-border content">
          <h2 v-if="pupil">
            History
          </h2>
        </div>
        <div v-if="$store.getters.isPremium || $store.getters.hasNumber" class="box-body">
          <b-table
            v-if="historyData"
            :data.sync="historyData"
            :paginated="true"
            :per-page="10"
            :mobile-cards="true"
            :striped="true"
            :selectable="false"
            :sort="false"
          >
            <template slot-scope="props">
              <b-table-column field="id" :visible="false">
                {{ props.row.id }}
              </b-table-column>
              <b-table-column field="time" label="Time" item-width="10px">
                <p class="small">
                  {{ dateFromServerDate(props.row.timestamp) }}
                </p>
              </b-table-column>
              <b-table-column field="key" label="Category">
                <p>{{ getNumberGameTitle(props.row.key) }} <a href="#" @click.prevent="showGameDetail(props.row.id)"><i class="fa fa-info-circle" /></a></p>
                <div :id="'gameDetail' + props.row.id" style="display:none">
                  <a href="#" @click.prevent="showChartFor(props.row.key)"><i class="fas fa-chart-line" aria-hidden="true" /> View List Competence</a><br>
                  <ul>
                    <li v-for="(res, index) in props.row.resultsData">
                      {{ res.question }} <i :class="res.correct === '1' ? 'fa fa-check text-green' : 'fa fa-times text-red'" /> <small v-if="res.correct !== '1' && res.answerGiven" class="has-text-danger">{{ res.answerGiven }}</small>
                    </li>
                  </ul>
                </div>
              </b-table-column>
              <b-table-column field="difficulty" label="Level">
                <small>{{ props.row.difficulty === 1 ? 'Easy' : props.row.difficulty === 2 ? 'Medium' : props.row.difficulty === 3 ? 'Hard' : 'Extreme' }}</small>
              </b-table-column>
              <b-table-column field="percentage" label="%">
                <small>{{ parseInt(props.row.percentage_score * 100) }}% ({{ props.row.correct_count }}/{{ props.row.total_count }})</small>
              </b-table-column>
              <b-table-column field="score" label="Score">
                <small>{{ props.row.score.toLocaleString() }}</small>
              </b-table-column>
              <b-table-column field="" label="">
                <button class="button is-small is-danger is-pulled-right" @click="deleteGameplay(props.row.id)">
                  <i class="fas fa-times" />
                </button>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered content">
                  <p>
                    <b-icon
                      custom-class="far"
                      pack="fa"
                      icon="frown"
                      size="is-large"
                    />
                  </p>
                  <p>Nothing here.</p>
                </div>
              </section>
            </template>
          </b-table>
          <div v-else class="alert">
            <b>{{ response }}</b>
          </div>
          <!-- </div>
          </div> -->
        </div>
        <div v-else class="box-body text-center content">
          <h2>Premium Feature</h2>
          <p>Upgrade to premium to use this feature.</p>
          <router-link v-if="isAdmin" to="/manage-subscription" class="btn button is-link" data-dismiss="modal">
            Upgrade
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import Chartist from 'chartist'
import VueChartist from 'v-chartist'
import Switches from 'vue-switches'
import _ from 'lodash'
import StringConversions from '@/mixins/StringConversions.ts'
import SelectNumberGameType from '@/components/views/components/SelectNumberGameType'
import { request } from '@/edshed-common/api'
require('chartist-plugin-legend')

export default {
  name: 'PupilMathsHistory',
  components: {
    VueChartist,
    Chartist,
    Switches,
    SelectNumberGameType
  },
  mixins: [StringConversions],
  props: {
    pupil: null
  },
  data (router) {
    return {
      loading: '',
      historyData: null,
      selectedWord: null,
      aggregate: true,
      response: 'Data Loading...',
      chart: null,
      numberGameType: 'numberBonds',
      list: ''
    }
  },
  computed: {
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getHistoryData()
    })
  },
  methods: {
    deleteGameplay (id) {
      const c = confirm('Are you sure you want to delete this game play record? This cannot be undone.')
      if (c) {
        // do stuff
        request('DELETE', 'users/me/school/pupils/' + this.pupil.username + '/mathshistory/' + id, null)
          .then((response) => {
            this.getHistoryData()
            // alert('Success')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    didChange () {
      console.log('changed')
      this.$nextTick(() => {
        this.renderChart()
      })
    },
    resetList () {
      this.list = ''
    },
    renderChart () {
      let series = []

      // now we need a series for each word
      // get all words in all plays in case of changes
      // var points = []
      for (let i = 0; i < this.historyData.length; i++) {
        if (this.historyData[i].key === this.list) {
          series.push({ x: moment(this.historyData[i].timestamp + ' 00:00:00', 'YYYY/MM/DD HH:mm:ss').toDate(), y: this.historyData[i].duration / (this.historyData[i].correct_count * 1000) })
        }
      }
      // this.chart = null
      series = _.chain(series)
        .groupBy('x')
        .map(function (g, k) {
          return {
            x: moment(k).toDate(),
            y: _.chain(g)
              .map('y')
              .reduce(function (x, y) { return x + y })
              .value() / g.length
          }
        })
        .value()
      const aggSer = { name: 'Average time', data: series }
      console.log(aggSer)
      this.chart = new Chartist.Line('#chart1', { series: [aggSer] },
        {
          axisX: {
            type: Chartist.FixedScaleAxis,
            divisor: 5,
            labelInterpolationFnc (value) {
              return moment(value).format('MMM D')
            }
          },
          fullWidth: true,
          chartPadding: {
            right: 40
          },
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0.1,
            fillHoles: true
          }),
          low: 0,
          height: 300
        })
    },
    showChartFor (key) {
      const sp = key.split('_')
      this.numberGameType = sp[0]
      this.$nextTick(() => {
        this.list = key
        this.renderChart()
      })
    },
    getHistoryData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/pupils/' + this.pupil.username + '/mathshistory', null)
        .then((response) => {
          const data = response.data
          this.historyData = data.data

          if (!this.historyData || this.historyData.length === 0) {
            this.response = 'No Groups'
          }

          for (let i = 0; i < this.historyData.length; i++) {
            const resArr = this.historyData[i].results.split('|')
            const resArr3 = []
            for (let j = 0; j < resArr.length; j++) {
              const resArr2 = resArr[j].split(':')
              const row = { question: resArr2[0], correct: resArr2[1], answerGiven: resArr2[2], time: resArr2[3] }
              resArr3.push(row)
            }
            this.historyData[i].resultsData = resArr3
          }

          this.renderChart()
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    dateFromServerDate (dt) {
      if (dt === null || dt === '' || !moment(dt).isValid()) {
        return null
      }
      const m = moment(dt)
      // var utcOffset = new Date().getTimezoneOffset()
      // m.add({minutes: utcOffset})
      const dts = m.toDate().toLocaleDateString() + ' ' + m.format('HH:mm')
      return dts // m.format('DD/MM/YYYY HH:mm')
    },
    showGameDetail (gameId) {
      $('#gameDetail' + gameId).toggle()
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>

<style scoped>
  .form-group {
    padding-right: 10px !important;
  }
</style>
<style lang="scss">
    @import "./node_modules/chartist/dist/scss/settings/_chartist-settings.scss";

    .ct-major-tenth svg {
      top: 20px;
    }
    .ct-legend {
      position: relative;
      z-index: 10;
      list-style: none;
      text-align:center;
      padding:10px 0;

      li {
          position: relative;
          padding-left: 23px;
          margin-right: 10px;
          margin-bottom: 3px;
          cursor: pointer;
          display: inline-block;
      }

      li:before {
          width: 12px;
          height: 12px;
          position: absolute;
          left: 0;
          content: '';
          border: 3px solid transparent;
          border-radius: 2px;
      }

      li.inactive:before {
          background: transparent;
      }

      &.ct-legend-inside {
          position: absolute;
          top: 0;
          right: 0;
      }

      @for $i from 0 to length($ct-series-colors) {
          .ct-series-#{$i}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
          .ct-series-#{$i+length($ct-series-colors)}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
          .ct-series-#{$i+length($ct-series-colors)*2}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
          .ct-series-#{$i+length($ct-series-colors)*3}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
          .ct-series-#{$i+length($ct-series-colors)*4}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
          .ct-series-#{$i+length($ct-series-colors)*5}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
          .ct-series-#{$i+length($ct-series-colors)*6}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
          .ct-series-#{$i+length($ct-series-colors)*7}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
          .ct-series-#{$i+length($ct-series-colors)*8}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
          .ct-series-#{$i+length($ct-series-colors)*9}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
          .ct-series-#{$i+length($ct-series-colors)*10}:before {
              background-color: nth($ct-series-colors, $i + 1);
              border-color: nth($ct-series-colors, $i + 1);
          }
      }
  }

  .field .b-radio {
    font-size: 12px
  }
  </style>
