
































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Validator } from 'vee-validate'
import $ from 'jquery'
import utils from '@/utils'
import loginPDF from '@/loginPDF'
import store from '@/store'
import { isAxiosError, request } from '@/edshed-common/api'
import PupilWritingPieces from './components/PupilWritingPieces.vue'

// Bypass type checking for components still written in JavaScript
const { default: LoginDetails } = require('@/components/views/components/LoginDetails')
const { default: AvatarView } = require('@/components/views/AvatarView')
const { default: PupilAvatar } = require('./components/PupilAvatar')
const { default: PupilLists } = require('./components/PupilLists')
const { default: PupilHistory } = require('./components/PupilHistory')
const { default: PupilMathsHistory } = require('./components/PupilMathsHistory')
const { default: PupilWords } = require('./components/PupilWords')
const { default: PupilGroups } = require('./components/PupilGroups')
Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'Pupil',
  components: {
    PupilGroups,
    PupilAvatar,
    PupilLists,
    PupilHistory,
    PupilMathsHistory,
    PupilWords,
    PupilWritingPieces,
    LoginDetails,
    AvatarView
  }
})
export default class PupilView extends Vue {
  private loading = ''
  private pupil: any = null
  private response = 'Data Loading...'
  private modalResponse = ''
  private username = ''
  private name = ''
  private displayName = ''
  private email = ''
  private upn = ''
  private password: string | null = null
  private passwordBeingSet = ''
  private showUserPassword = false
  private locale = ''
  private tabIndex = 0

  // Redirect to new home for non-superusers
  beforeRouteEnter (to, from, next) {
    if (store.state.user && store.state.user.superuser) {
      next()
    } else {
      let locale = 'en-gb'
      if (store.state.user && store.state.user.locale && store.state.user.locale) {
        locale = store.state.user.locale.toLowerCase().replace('_', '-')
      }
      window.location.replace(`https://www.edshed.com/${locale}/pupils/${to.params.id}`)
    }
  }

  mounted () {
    this.$nextTick(() => {
      this.getPupilData()
    })
    const dictionary = {
      en_GB: {
        attributes: {
          name: 'Name',
          email: 'Email',
          username: 'Username'
        }
      }
    }
    Validator.localize('en_GB', dictionary.en_GB)
  }

  dataTabs (ind) {
    this.tabIndex = ind
  }

  localeInfo () {
    const locale = this.pupil.locale
    let loc = '<abbr title="United Kingdom">🇬🇧</abbr>'
    if (locale === 'en_AU') {
      loc = '<abbr title="Australia">🇦🇺</abbr>'
    } else if (locale === 'en_IE') {
      loc = '<abbr title="Ireland">🇮🇪</abbr>'
    } else if (locale === 'en_NZ') {
      loc = '<abbr title="New Zealand">🇳🇿</abbr>'
    } else if (locale === 'en_US') {
      loc = '<abbr title="United States">🇺🇸</abbr>'
    }
    return loc
  }

  print () {
    // window.print()
    this.generatePDF()
  }

  generatePDF () {
    loginPDF.generatePDFForUsers([this.pupil])
  }

  showPassword () {
    this.showUserPassword = true
  }

  forgetPassword () {
    const c = confirm('Forget password on this computer? The password will still work for the pupil.')
    if (c) {
      if (window.localStorage) {
        window.localStorage.removeItem('password:' + this.username)
      }
      this.password = null
    }
  }

  clearPasswordBeingSet () {
    this.passwordBeingSet = ''
  }

  didSetPasswords () {
    // generate array of user : password
    const ids = [{ id: this.pupil.id, password: this.passwordBeingSet }]
    this.setPasswords(ids)
    this.passwordBeingSet = ''
  }

  didSetRandomPasswords () {
    // generate array of user : password
    const ids = [{ id: this.pupil.id, password: this.generatePassword() }]
    this.setPasswords(ids)
    this.passwordBeingSet = ''
  }

  generatePassword () {
    return utils.generatePassword()
  }

  async setPasswords (pupilIdsArray) {
    if (pupilIdsArray.length === 0) {
      alert('No selection')
      return
    }
    this.toggleLoading()
    this.$store.commit('TOGGLE_LOADING')

    try {
      const response = await request('PUT', 'users/me/school/pupils', { pupils: pupilIdsArray })
      const pupils = response.data.pupils
      console.log('aaa' + pupils)
      this.pupil = pupils[0]
      this.password = this.pupil.password
      if (this.pupil.password && window.localStorage) {
        window.localStorage.setItem('password:' + this.username, this.pupil.password)
      }
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
    } catch (error: unknown) {
      console.log(error)
      this.$store.commit('TOGGLE_LOADING')
      this.toggleLoading()

      if (isAxiosError(error) && error.response?.status === 403) {
        console.log('FORBIDDEN')
        this.$router.push('/logout')
      }

      this.response = 'Details incorrect'
    }
  }

  async getPupilData () {
    this.toggleLoading()
    this.$store.commit('TOGGLE_LOADING')

    try {
      const response = await request('GET', 'users/me/school/pupils/' + this.$route.params.id, null)
      const data = response.data

      if (!data.pupil) {
        this.response = 'No Pupil'
        this.$router.push('/pupils/')
      }

      this.pupil = data.pupil
      this.name = this.pupil.name
      this.displayName = this.pupil.display_name
      this.username = this.pupil.username
      this.email = this.pupil.email
      this.upn = this.pupil.upn
      this.locale = this.pupil.locale

      this.pupil.password = this.passwordForUsername(this.username)
      if (this.pupil.password) {
        this.password = this.pupil.password
      }

      // console.log('readerData: ' + this.readerData)

      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
    } catch (error: unknown) {
      console.log(error)
      this.$store.commit('TOGGLE_LOADING')
      this.toggleLoading()

      if (isAxiosError(error) && error.response?.status === 403) {
        console.log('FORBIDDEN')
        this.$router.push('/logout')
      }

      this.response = 'Details incorrect'
    }
  }

  passwordForUsername (username) {
    if (window.localStorage) {
      return window.localStorage.getItem('password:' + username)
    } else {
      return null
    }
  }

  toggleLoading () {
    this.loading = (this.loading === '') ? 'loading' : ''
  }

  beforeSavePupil () {
    this.$validator.validateAll().then((result) => {
      if (result) {
        this.savePupil()
      }
    })
  }

  async savePupil () {
    console.log('add')
    const { name, displayName, username, email, password, upn, locale } = this

    $('#myModal').removeClass('in')
    $('#myModal').hide()
    $('.modal-backdrop').remove()

    this.toggleLoading()
    this.$store.commit('TOGGLE_LOADING')

    const d = { name, displayName, username, email, password, upn, locale }

    // if (!email || email === '') {
    //   delete d.email
    // }

    // if (!upn || upn === '') {
    //   delete d.upn
    // }

    /* Making API call to authenticate a user */
    try {
      const response = await request('PUT', 'users/me/school/pupils/' + this.pupil.username, d)
      this.toggleLoading()

      const oldUsername = this.pupil.username

      // this.name = ''
      // this.username = ''
      // this.email = ''
      this.password = ''
      // this.upn = ''
      // this.locale = ''

      window.console.log('Response', response)
      const data = response.data
      if (data.error) {
        alert(data.error)
      } else {
        this.pupil = data.pupil

        this.name = this.pupil.name
        this.username = this.pupil.username
        this.email = this.pupil.email
        this.upn = this.pupil.upn
        this.locale = this.pupil.locale

        this.pupil.password = this.passwordForUsername(this.username)
        if (this.pupil.password) {
          this.password = this.pupil.password
        }

        if (!this.pupil) {
          this.response = 'No Pupil'
        }
      }
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      if (oldUsername !== this.pupil.username) {
        this.$router.push('/pupils/' + this.pupil.username)
      }
    } catch (error: unknown) {
      console.log(error)
      this.$store.commit('TOGGLE_LOADING')
      this.toggleLoading()

      if (isAxiosError(error) && error.response?.status === 403) {
        console.log('FORBIDDEN')
        this.$router.push('/logout')
      }

      this.response = 'Details incorrect'
    }
  }
}

