<template>
  <!-- Main content -->
  <div class="box box-info">
    <div class="box-header with-border content">
      <h2 v-if="pupil" class="is-pulled-left">
        Honey Shop
      </h2><span class="tag is-default is-pulled-right is-medium">{{ pupil.total_earnings }}🍯</span>
    </div>
    <div class="box-body">
      <div>
        <h4 style="margin: 5px;">
          <b>Add Reward / Fine</b>
        </h4>
        <b-field>
          <b-select v-model="extraType" placeholder="Reward or Fine">
            <option value="reward">
              Reward
            </option>
            <option value="fine">
              Fine
            </option>
          </b-select>
          <b-input
            v-model="extraAmount"
            placeholder="Amount"
            type="number"
            min="1"
            max="1000"
            expanded
          />
          <p class="control">
            <button v-if="extraType == 'reward'" :key="'reward'" class="button is-success is-outlined" @click="addReward">
              <span class="icon text-yellow"><i class="fas fa-trophy" /></span> <span>Save</span>
            </button>
            <button v-if="extraType == 'fine'" :key="'fine'" class="button is-danger is-outlined" @click="addReward">
              <span class="icon text-red"><i class="fas fa-ban" /></span> <span>Save</span>
            </button>
          </p>
        </b-field>
      </div>
      <b-table
        v-if="purchaseData"
        :data.sync="purchaseData"
        :paginated="true"
        :per-page="10"
        :mobile-cards="true"
        :striped="true"
        :selectable="false"
        :sort="true"
        default-sort-direction="desc"
        default-sort="timestamp"
      >
        <template slot-scope="props">
          <b-table-column :key="props.row.id" field="timestamp" label="Item" item-width="10px" sortable>
            <AvatarView
              v-if="props.row.type != 'fine' && props.row.type != 'reward'"
              style="float:left; margin-right:10px;"
              :uniq="'user-'+props.row.id"
              :data="dataForItem(props.row)"
              scale-factor="0.35"
              class="is-pulled-left"
            />
            <span v-else-if="props.row.type == 'fine'" class="icon text-red is-pulled-left accessoryIcon"><i class="fas fa-ban" /></span>
            <span v-else-if="props.row.type == 'reward'" class="icon text-yellow is-pulled-left accessoryIcon"><i class="fas fa-trophy" /></span>
            <p>{{ nameForPart(props.row.type, props.row.key) }}</p><p /><p><small>{{ displayDate(props.row.timestamp) }}</small></p>
          </b-table-column>
          <b-table-column fiel="cost" label="Cost" item-width="10px">
            {{ props.row.type === 'reward' ? '(+' : '' }}🍯{{ Math.abs(props.row.cost).toLocaleString() }}{{ props.row.type === 'reward' ? ')' : '' }}
          </b-table-column>
          <b-table-column field="actions" label="">
            <button class="button is-danger" @click="deletePurchase(props.row)">
              <i class="fa fa-times" />
            </button>
            <!-- <div class="btn-group is-pulled-right">
                    <router-link :to="'/groups/'+props.row.id" class="btn button is-link">Details</router-link>
                    <button type="button" class="btn button is-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="caret"></span>
                      <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li><a href="#" @click.prevent="removeFromGroup(props.row.id)">Remove</a></li>
                    </ul>
                  </div> -->
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered content">
              <p>
                <b-icon
                  custom-class="far"
                  pack="fa"
                  icon="frown"
                  size="is-large"
                />
              </p>
              <p>Nothing here.</p>
            </div>
          </section>
        </template>
      </b-table>
      <div v-else class="alert">
        <b>{{ response }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/edshed-common/api'
import utils from '@/utils'
import moment from 'moment'
import AvatarView from '@/components/views/AvatarView'

export default {
  name: 'PupilAvatar',
  components: {
    AvatarView
  },
  props: {
    pupil: null
  },
  data (router) {
    return {
      loading: '',
      extraType: 'reward',
      extraAmount: 0,
      purchaseData: null,
      response: 'Data Loading...',
      purchasableItems: [
        { type: 'shirtType', name: 'Striped Shirt', key: 'stripe', cost: 10 },
        { type: 'shirtType', name: 'Striped Shirt', key: 'stripe2', cost: 10 },
        { type: 'shirtType', name: 'Hexagon Shirt', key: 'hex', cost: 10 },
        { type: 'shirtType', name: 'Pocket Shirt', key: 'pocket', cost: 10 },
        { type: 'shirtType', name: 'Circle Shirt', key: 'circle', cost: 10 },
        { type: 'shirtType', name: 'Vee Neck Shirt', key: 'vee', cost: 10 },
        { type: 'shirtType', name: 'Wide Neck Shirt', key: 'wide', cost: 10 },
        { type: 'shirtType', name: 'Zip Shirt', key: 'zip', cost: 10 },
        { type: 'shirtType', name: 'Buttoned Shirt', key: 'buttons', cost: 25 },
        { type: 'shirtType', name: 'Buttoned Shirt', key: 'buttons2', cost: 25 },
        { type: 'shirtType', name: 'Collar Shirt', key: 'collar', cost: 25 },
        { type: 'shirtType', name: 'Vee Neck Collar Shirt', key: 'veeCollar', cost: 25 },
        { type: 'shirtType', name: 'Collar Buttoned Shirt', key: 'collarButtons', cost: 25 },
        { type: 'shirtType', name: 'ZigZag Shirt', key: 'zigZag', cost: 25 },
        { type: 'shirtType', name: 'Wave Shirt', key: 'wave', cost: 25 },
        { type: 'shirtType', name: 'Pull String Shirt', key: 'pullStrings', cost: 50 },
        { type: 'shirtType', name: 'Tassles Shirt', key: 'tassles', cost: 50 },
        { type: 'shirtType', name: 'Flower Shirt', key: 'shirtflower', cost: 50 },
        { type: 'shirtType', name: 'Pearls Shirt', key: 'shirtpearls', cost: 50 },
        { type: 'shirtType', name: 'Mid-Stripe Shirt', key: 'shirtmidstripe', cost: 50 },
        { type: 'shirtType', name: 'Frilled Collar Shirt', key: 'shirtvfrills', cost: 50 },
        { type: 'shirtType', name: 'ZigZag Shirt', key: 'shirthzigzag', cost: 50 },
        { type: 'shirtType', name: 'Shawl Shirt', key: 'shirthijab', cost: 50 },
        { type: 'shirtType', name: 'Stiches Shirt', key: 'shirthalloween', cost: 50 },
        { type: 'shirtType', name: 'Bowtie Shirt', key: 'shirtbowtie', cost: 50 },
        { type: 'shirtType', name: 'Tie Shirt', key: 'shirttie', cost: 50 },
        { type: 'shirtType', name: 'Check Shirt', key: 'shirtcheck', cost: 50 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball1', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball2', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball3', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball4', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball5', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball6', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball7', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball8', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball9', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball10', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball11', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball12', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball13', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball14', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball15', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball16', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball17', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball18', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball19', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball20', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball21', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball22', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball23', cost: 250 },
        { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball24', cost: 250 },
        { type: 'backgroundType', name: 'Pattern Background', key: 'pattern1', cost: 500 },
        { type: 'backgroundType', name: 'Pattern Background', key: 'pattern2', cost: 500 },
        { type: 'backgroundType', name: 'Pattern Background', key: 'pattern3', cost: 500 },
        { type: 'backgroundType', name: 'Pattern Background', key: 'pattern4', cost: 500 },
        { type: 'backgroundType', name: 'Pattern Background', key: 'pattern5', cost: 500 },
        { type: 'backgroundType', name: 'Pattern Background', key: 'pattern6', cost: 500 },
        { type: 'backgroundType', name: 'Pattern Background', key: 'pattern7', cost: 500 },
        { type: 'backgroundType', name: 'Pattern Background', key: 'pattern8', cost: 500 },
        { type: 'backgroundType', name: 'Pattern Background', key: 'pattern9', cost: 500 },
        { type: 'backgroundType', name: 'Pattern Background', key: 'pattern10', cost: 500 },
        { type: 'backgroundType', name: 'Sea Background', key: 'sea', cost: 1000 },
        { type: 'backgroundType', name: 'Desert Background', key: 'desert', cost: 1000 },
        { type: 'backgroundType', name: 'Beach Background', key: 'beach', cost: 1000 },
        { type: 'backgroundType', name: 'Space Background', key: 'space', cost: 1000 },
        { type: 'backgroundType', name: 'Forest Background', key: 'forest', cost: 1000 },
        { type: 'backgroundType', name: 'Mountains Background', key: 'mountains', cost: 1000 },
        { type: 'backgroundType', name: 'Egypt Background', key: 'egypt', cost: 1000 },
        { type: 'backgroundType', name: 'Castle Background', key: 'castle', cost: 1000 },
        { type: 'backgroundType', name: 'Hearts Background', key: 'hearts', cost: 1000 },
        { type: 'backgroundType', name: 'Rainbow Background', key: 'rainbow', cost: 1000 },
        { type: 'backgroundType', name: 'Snowy Background', key: 'snowy', cost: 1000 },
        { type: 'backgroundType', name: 'Sea Background', key: 'sea2', cost: 1000 },
        { type: 'backgroundType', name: 'Zodiac Background', key: 'zodiac', cost: 1000 },
        { type: 'backgroundType', name: 'Art Background', key: 'art', cost: 1000 },
        { type: 'backgroundType', name: 'World Background', key: 'world', cost: 1000 },
        { type: 'backgroundType', name: 'World Background', key: 'world2', cost: 1000 },
        { type: 'backgroundType', name: 'Map Background', key: 'map', cost: 1000 },
        { type: 'backgroundType', name: 'Music Background', key: 'music', cost: 1000 },
        { type: 'backgroundType', name: 'Romans Background', key: 'romans', cost: 1000 },
        { type: 'backgroundType', name: 'Chemistry Background', key: 'chemistry', cost: 2000 },
        { type: 'backgroundType', name: 'Football Background', key: 'football', cost: 2000 },
        { type: 'backgroundType', name: 'Balloons Background', key: 'balloons', cost: 2000 },
        { type: 'face', name: 'Round Glasses', key: 'roundGlasses', cost: 500 },
        { type: 'face', name: 'Angled Glasses', key: 'angledGlasses', cost: 500 },
        { type: 'face', name: 'Cool Shades', key: 'coolShades', cost: 500 },
        { type: 'face', name: 'Dark Shades', key: 'darkShades', cost: 500 },
        { type: 'face', name: 'Cat Mask', key: 'catMask', cost: 2000 },
        { type: 'face', name: 'Puppy Mask', key: 'puppyMask', cost: 2000 },
        { type: 'face', name: 'Panda Mask', key: 'pandaMask', cost: 2000 },
        { type: 'face', name: 'Disguise Mask', key: 'disguiseMask', cost: 2000 },
        { type: 'face', name: 'Superhero Mask', key: 'batmanMask', cost: 2000 },
        { type: 'face', name: 'Scary Mask', key: 'scaryMask', cost: 2000 },
        { type: 'face', name: 'Eye Patch', key: 'eyePatch', cost: 2000 },
        { type: 'face', name: 'Snorkel', key: 'snorkel', cost: 10000 },
        { type: 'face', name: 'Lightning', key: 'facelightning', cost: 1000 },
        { type: 'face', name: 'Mouse Nose', key: 'facemousenose', cost: 500 },
        { type: 'face', name: 'Gold Hoop Earrings', key: 'goldhoopearrings', cost: 100 },
        { type: 'face', name: 'Square Glasses', key: 'squareglasses', cost: 0 },
        { type: 'face', name: 'Round Glasses', key: 'roundglasseswhite', cost: 0 },
        { type: 'face', name: 'Beard', key: 'beardsmall', cost: 500 },
        { type: 'face', name: 'Mustache', key: 'longmustache', cost: 500 },
        { type: 'face', name: 'Lines Glasses', key: 'glasseslines', cost: 500 },
        { type: 'face', name: 'Bee Wings', key: 'wingsbee', cost: 50000 },
        { type: 'face', name: 'Wand', key: 'wand', cost: 5000 },
        { type: 'face', name: 'Pig Mask', key: 'pigMask', cost: 2000 },
        { type: 'face', name: 'Elephant Mask', key: 'elephantMask', cost: 5000 },
        { type: 'face', name: 'Giraffe Mask', key: 'giraffeMask', cost: 2000 },
        { type: 'face', name: 'Lion Mask', key: 'lionMask', cost: 5000 },
        { type: 'face', name: 'Tiger Mask', key: 'tigerMask', cost: 2000 },
        { type: 'face', name: 'Reindeer Mask', key: 'reindeerMask', cost: 10000 },
        { type: 'face', name: 'Zebra Mask', key: 'zebraMask', cost: 2000 },
        { type: 'face', name: 'Dog Mask', key: 'dogMask', cost: 2000 },
        { type: 'face', name: 'Unicorn Mask', key: 'unicornMask', cost: 10000 },
        { type: 'face', name: 'Monkey Mask', key: 'monkeyMask', cost: 2000 },
        { type: 'accessory', name: 'Dark Shades', key: 'darkShades', cost: 500 },
        { type: 'accessory', name: 'Flower Headband', key: 'flowerHairband', cost: 100 },
        { type: 'accessory', name: 'Star Headband', key: 'starHeadband', cost: 100 },
        { type: 'accessory', name: 'Green Headband', key: 'greenHeadband', cost: 100 },
        { type: 'accessory', name: 'Orange Stripy Headband', key: 'orangeHeadband', cost: 100 },
        { type: 'accessory', name: 'Pink Headphones', key: 'pinkHeadphones', cost: 500 },
        { type: 'accessory', name: 'Green Headphones', key: 'greenHeadphones', cost: 500 },
        { type: 'accessory', name: 'Star Cap', key: 'pinkCap', cost: 1000 },
        { type: 'accessory', name: 'Blue Cap', key: 'blueCap', cost: 1000 },
        { type: 'accessory', name: 'Wooly Hat', key: 'blueTassleHat', cost: 1000 },
        { type: 'accessory', name: 'Pink Wooly Hat', key: 'pinkWoolyHat', cost: 1000 },
        { type: 'accessory', name: 'Blue Wooly Hat', key: 'blueWoolyHat', cost: 1000 },
        { type: 'accessory', name: 'Green Wooly Hat', key: 'greenWoolyHat', cost: 1000 },
        { type: 'accessory', name: 'Pink Scarf', key: 'pinkScarf', cost: 1000 },
        { type: 'accessory', name: 'Blue Scarf', key: 'blueScarf', cost: 1000 },
        { type: 'accessory', name: 'Head Scarf', key: 'headScarf', cost: 2000 },
        { type: 'accessory', name: 'Jester Hat', key: 'jesterHat', cost: 5000 },
        { type: 'accessory', name: 'Pirate Hat', key: 'pirateHat', cost: 5000 },
        { type: 'accessory', name: 'Astronaut Helmet', key: 'astronaut', cost: 10000 },
        { type: 'accessory', name: 'Sombrero', key: 'sombrero', cost: 10000 },
        { type: 'accessory', name: 'Crown', key: 'crown', cost: 25000 },
        { type: 'accessory', name: 'Red Flower', key: 'redflower', cost: 100 },
        { type: 'accessory', name: 'Laurel', key: 'laurel', cost: 1000 },
        { type: 'accessory', name: 'Wizard Hat', key: 'wizardhat', cost: 10000 },
        { type: 'accessory', name: 'Green Hair Bow', key: 'greenhairbow', cost: 100 },
        { type: 'accessory', name: 'Cowboy Hat', key: 'cowboyhat', cost: 5000 },
        { type: 'accessory', name: 'Witch Hat', key: 'witchhat', cost: 10000 },
        { type: 'accessory', name: 'Rabbit Ears', key: 'rabbitearsheadband', cost: 2000 },
        { type: 'accessory', name: 'Small Green Hair Bows', key: 'smallgreenbows', cost: 100 },
        { type: 'accessory', name: 'Hair Pearls', key: 'hairpearls', cost: 100 },
        { type: 'accessory', name: 'Viking Helm', key: 'helmetviking', cost: 25000 },
        { type: 'accessory', name: 'Pharoah Mask', key: 'helmetegypt', cost: 25000 },
        { type: 'accessory', name: 'Red Beret', key: 'hatberetred', cost: 1000 },
        { type: 'accessory', name: 'Backwards Cap', key: 'hatcapbackwards', cost: 1000 },
        { type: 'accessory', name: 'Small Cap', key: 'hatkippah', cost: 100 },
        { type: 'accessory', name: 'Santa Hat', key: 'hatsanta', cost: 25000 },
        { type: 'accessory', name: 'Chef Hat', key: 'hatchef', cost: 10000 },
        { type: 'accessory', name: 'Bee Headband', key: 'headbandbee', cost: 50000 }
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
      moment.locale(this.$store.state.user.locale)
      this.getAvatarPurchases()
    })
  },
  methods: {
    addReward () {
      let amount = parseInt(this.extraAmount, 10)
      if (this.extraType === 'reward') {
        amount = -amount
      }
      console.log('Type: ' + this.extraType)
      console.log('Amount: ' + amount)

      if (amount === 0 || isNaN(amount)) {
        alert('Amount must be more than 0')
        return
      }
      request('POST', 'users/me/school/pupils/' + this.pupil.username + '/avatarpurchases', { type: this.extraType, amount })
        .then((response) => {
          const data = response.data
          if (data.user) {
            this.$store.commit('SET_USER', data.user)
            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }
          }

          this.getAvatarPurchases()
          this.pupil.total_earnings = this.pupil.total_earnings - amount
          // console.log('readerData: ' + this.readerData)
          if (!this.purchaseData || this.purchaseData.length === 0) {
            this.response = 'No Purchases'
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    deletePurchase (item) {
      const c = confirm('Are you sure you wish to delete this item?')
      if (c) {
        console.log(item)
        request('DELETE', 'users/me/school/pupils/' + this.pupil.username + '/avatarpurchases/' + item.id, null)
          .then((response) => {
            const data = response.data
            if (data.user) {
              this.$store.commit('SET_USER', data.user)
              if (window.localStorage) {
                window.localStorage.setItem('user', JSON.stringify(data.user))
              }
            }

            this.getAvatarPurchases()
            this.pupil.total_earnings = this.pupil.total_earnings + item.cost
            // console.log('readerData: ' + this.readerData)
            if (!this.purchaseData || this.purchaseData.length === 0) {
              this.response = 'No Purchases'
            }
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    dataForItem (item) {
      // console.log(item)
      const it = {}
      if (item.type && item.key) {
        let type = item.type
        if (type === 'backgroundPattern') {
          type = 'backgroundType'
        }
        it[type] = item.key
      }
      // console.log(it)
      return it
    },
    nameForPart (type, key) {
      if (type === 'backgroundPattern') {
        type = 'backgroundType'
      }
      const fl = this.purchasableItems.filter(obj => obj.type === type && obj.key === key)
      // console.log(nm)
      const it = fl[0]
      let nm = '||' + type + ' ' + key + '||'
      if (type === 'fine') {
        nm = 'Fine'
      }
      if (type === 'reward') {
        nm = 'Reward'
      }
      if (it) {
        nm = it.name
      }
      return nm
    },
    displayDate (d) {
      return utils.formattedDateTime(d)
    },
    getAvatarPurchases () {
      request('GET', 'users/me/school/pupils/' + this.pupil.username + '/avatarpurchases', null)
        .then((response) => {
          const data = response.data
          this.purchaseData = data.items

          // console.log('readerData: ' + this.readerData)
          if (!this.purchaseData || this.purchaseData.length === 0) {
            this.response = 'No Purchases'
          }
        })
        .catch((error) => {
          console.log(error)

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    }
  }
}
</script>
<style>
.accessoryIcon {
  font-size: 87px;
  padding: 32px 38px 32px 27px;
}
</style>
