

























































import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { PagedResults, TableState, WritingPieceLite } from '@/edshed-common/api/types'
import { request } from '@/edshed-common/api'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import api from '@/api'

@Component({ props: ['pupil'] })
export default class PupilWritingPieces extends mixins(ModalMixin) {
  private pupil!: { id: number, username: string }

  private tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  private results: PagedResults<WritingPieceLite> = {
    items: [],
    total: 0
  }

  public mounted () {
    this.$nextTick(() => {
      this.loadData()
    })
  }

  private async loadData () {
    const { perPage, page, sort, dir } = this.tableState

    this.results = await api.getPaged(
      'writing_pieces',
      this.tableState,
      { owner_id: String(this.pupil.id) }
    )
  }

  private onPageChange (page: number) {
    this.tableState.page = page

    this.loadData()
  }

  private onSortChange (col: keyof WritingPieceLite) {
    const { sort, dir } = this.tableState

    if (sort !== col) {
      this.tableState.sort = col
      this.tableState.dir = 'asc'
    } else {
      this.tableState.dir = dir === 'asc' ? 'desc' : 'asc'
    }

    this.loadData()
  }

  private onSearchChange () {
    this.loadData()
  }

  private onDelete (row: WritingPieceLite) {
    this.onDeleteRow(row)
  }

  private async onDeleteRow (row: WritingPieceLite) {
    try {
      if (await this.deleteConfirm({ name: row.title })) {
        await api.delete(
          'writing_pieces',
          row.id!
        )

        this.loadData()
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        await this.openModal({
          title: 'Error',
          message: err.message,
          buttons: [{ key: 'ok', type: 'primary', label: 'OK' }]
        })
      }
    }
  }
}
