<template>
  <!-- Main content -->
  <div class="box box-danger">
    <div class="box-header with-border content">
      <h2 v-if="pupil">
        Lists
      </h2>
      <div class="box-tools pull-right" />
    </div>
    <div class="box-body">
      <b-table
        v-if="listData"
        :data.sync="listData"
        :paginated="true"
        :per-page="10"
        :mobile-cards="true"
        :striped="true"
        :selectable="false"
        :sort="false"
      >
        <template slot-scope="props">
          <b-table-column field="name" label="Name" item-width="10px">
            {{ props.row.title }} <span v-if="props.row.favourite">❤️</span>
          </b-table-column>
          <b-table-column field="actions" label="">
            <div class="is-inline-flex">
              <router-link :to="'/list/'+props.row.ident" class="btn button is-link is-pulled-right" style="margin: 0 5px">
                Details
              </router-link>
              <button v-if="props.row.favourite" class="btn button is-danger is-pulled-right" style="margin-right:5px;" @click="unfavourite(props.row.ident)">
                {{ $store.state.user.locale == 'en_US' ? 'Unfavorite' : 'Unfavourite' }}
              </button>
              <!-- <div class="is-pulled-right" v-else> -->
              <b-dropdown v-else aria-role="list" class="is-pulled-right" position="is-bottom-left" style="margin-top:0">
                <button slot="trigger" class="button is-danger">
                  <span>Delete</span>
                  <b-icon
                    custom-class="fas"
                    pack="fa"
                    icon="angle-down"
                  />
                </button>
                <b-dropdown-item aria-role="listitem" @click="deleteList(props.row.ident)">
                  Delete List Only
                </b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="deleteListComplete(props.row.ident)">
                  Delete List and Games
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <!-- <button @click="deleteListComplete(props.row.ident)" class="btn button is-danger is-pulled-right" style="margin-right:5px;">Delete List and Games</button>
                  <button @click="deleteList(props.row.ident)" class="btn button is-danger is-pulled-right" style="margin-right:5px;">Delete List Only</button> -->
            <!-- </div> -->
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered content">
              <p>
                <b-icon
                  custom-class="far"
                  pack="fa"
                  icon="frown"
                  size="is-large"
                />
              </p>
              <p>Nothing here.</p>
            </div>
          </section>
        </template>
      </b-table>
      <div v-else class="alert">
        <b>{{ response }}</b>
      </div>
    </div>
    <!-- </div> -->
    <ChooseFavouriteList v-if="showChooseList" :pupil="pupil" @close="hideAddModal" />
  </div>
  </div>
</template>

<script>
import { request } from '@/edshed-common/api'
import ChooseFavouriteList from '@/components/views/components/ChooseFavouriteList'

export default {
  name: 'PupilLists',
  components: {
    ChooseFavouriteList
  },
  props: {
    pupil: null
  },
  data (router) {
    return {
      loading: '',
      listData: null,
      response: 'Data Loading...',
      showChooseList: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getListData()
    })
  },
  methods: {
    getListData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/pupils/' + this.pupil.username + '/lists', null)
        .then((response) => {
          const data = response.data
          this.listData = data.lists

          // console.log('readerData: ' + this.readerData)
          if (!this.listData || this.listData.length === 0) {
            this.response = 'No Lists'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
          this.getFavouriteListData()
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getFavouriteListData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/pupils/' + this.pupil.username + '/lists/favourites', null)
        .then((response) => {
          const data = response.data
          this.listData = this.listData.concat(data.lists)

          // console.log('readerData: ' + this.readerData)
          if (!this.listData || this.listData.length === 0) {
            this.response = 'No Lists'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    unfavourite (ident) {
      const c = confirm('Are you sure you want to remove this list?')
      if (c) {
        request('DELETE', 'lists/' + ident + '/favourite/pupils/' + this.pupil.id, null)
          .then((response) => {
            this.getListData()
            // alert('Success')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    deleteList (ident) {
      const c = confirm('Are you sure you want to delete this list?')
      if (c) {
        request('DELETE', 'lists/' + ident + '/pupils/' + this.pupil.id, null)
          .then((response) => {
            this.getListData()
            // alert('Success')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    deleteListComplete (ident) {
      const c = confirm('Are you sure you want to delete this list and ALL gameplays from ALL users? \n\nThis will decrease users\' Scores and should be used for e.g. cheating lists.')
      if (c) {
        request('DELETE', 'lists/' + ident + '/pupils/' + this.pupil.id, { cascade: 1 })
          .then((response) => {
            this.getListData()
            // alert('Success')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    showAddModal () {
      this.showChooseList = true
    },
    hideAddModal () {
      this.showChooseList = false
    }
  }
}
</script>
