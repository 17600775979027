<template>
  <!-- Main content -->
  <div>
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border content">
          <h2>Competence <span class="tag is-dark">PREMIUM</span></h2>
        </div>
        <div v-if="$store.getters.isPremium || $store.getters.hasNumber" class="box-body">
          <div id="pupilActivity" class="ct-chart" />
          <h4>Correct answers (%) by date</h4>
          <form class="form-inline text-center">
            <div class="radio form-group">
              <label for="typeSelector">List <input v-model="chartType" type="radio" value="list" name="type"></label>
            </div>
            <div class="radio form-group">
              <label for="typeSelector">Word <input v-model="chartType" type="radio" value="word" name="type"></label>
            </div>
            <div class="form-group">
              <select v-if="chartType == 'list'" id="sourceList" v-model="selectedList" class="form-control" @change="renderChart">
                <option v-for="(ls, index) in allLists" :value="ls.ident">
                  {{ ls.title }}
                </option>
              </select>
              <select v-if="chartType == 'word'" id="sourceWord" v-model="selectedWord" class="form-control" @change="renderChart">
                <option v-for="(word, index) in allWords" :value="word">
                  {{ word }}
                </option>
              </select>
            </div>
            <div v-if="chartType == 'list'" class="form-group">
              Aggregate data <switches v-model="aggregate" theme="default" color="green" />
            </div>
          </form>
          <div id="chart1" class="ct-chart" />
        </div>
        <div v-else class="box-body text-center content">
          <h2>Premium Feature</h2>
          <p>Upgrade to premium to use this feature.</p>
          <router-link v-if="isAdmin" to="/manage-subscription" class="btn button is-link" data-dismiss="modal">
            Upgrade
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="box box-success">
        <div class="box-header with-border content">
          <h2 v-if="pupil">
            History <span class="tag is-dark">PREMIUM</span>
          </h2>
        </div>
        <div v-if="$store.getters.isPremium" class="box-body">
          <div class="tabs">
            <ul>
              <li :class="{'is-active': activeTab === 0}">
                <a @click="setActiveTab(0)">Gameplays</a>
              </li>
              <li :class="{'is-active': activeTab === 3}">
                <a @click="setActiveTab(3)">Sentences</a>
              </li>
              <li :class="{'is-active': activeTab === 1}">
                <a @click="setActiveTab(1)">Beekeeper</a>
              </li>
              <li :class="{'is-active': activeTab === 2}">
                <a @click="setActiveTab(2)">Buzzwords</a>
              </li>
            </ul>
          </div>
          <b-table
            v-if="historyData && activeTab === 0"
            :data.sync="historyData"
            :paginated="true"
            :per-page="10"
            :mobile-cards="true"
            :striped="true"
            :selectable="false"
            :sort="false"
          >
            <template slot-scope="props">
              <b-table-column field="id" :visible="false">
                {{ props.row.id }}
              </b-table-column>
              <b-table-column field="time" label="Time" item-width="10px">
                <p class="small">
                  {{ dateFromServerDate(props.row.timestamp) }}
                </p>
              </b-table-column>
              <b-table-column field="list" label="List">
                <p>{{ props.row.list_title }} <a href="#" @click.prevent="showGameDetail(props.row.id)"><i class="fa fa-info-circle" /></a></p>
                <div :id="'gameDetail' + props.row.id" style="display:none">
                  <a href="#" @click.prevent="showChartFor('list', props.row.list_ident)"><i class="fas fa-chart-line" aria-hidden="true" /> View List Competence</a><br>
                  <router-link v-if="props.row.hive_id" :to="'/hives/' + props.row.hive_id">
                    <i class="fas fa-sitemap" aria-hidden="true" /> View Hive
                  </router-link>
                  <ul>
                    <li v-for="(res, index) in props.row.resultsData">
                      {{ res.word }}
                      <i v-if="parseInt(res.correct)" class="mdi mdi-check has-text-success" />
                      <i v-else class="mdi mdi-close has-text-danger" />
                      <small v-if="!parseInt(res.correct) && res.answerGiven" class="has-text-danger">{{ res.answerGiven }}</small> <a href="#" class="is-pulled-right" @click.prevent="showChartFor('word', res.word)"><i class="fas fa-chart-line" aria-hidden="true" /></a>
                    </li>
                  </ul>
                </div>
              </b-table-column>
              <b-table-column field="difficulty" label="Level">
                <small>{{ props.row.difficulty === 1 ? 'Easy' : props.row.difficulty === 2 ? 'Medium' : props.row.difficulty === 3 ? 'Hard' : 'Extreme' }}</small>
              </b-table-column>
              <b-table-column field="percentage" label="%">
                <small>{{ parseInt(props.row.percentage_score * 100) }}%</small>
              </b-table-column>
              <b-table-column field="score" label="Score">
                <small>{{ props.row.score.toLocaleString() }}</small>
              </b-table-column>
              <b-table-column field="earnings" label="Earnings">
                <small>🍯{{ props.row.earnings }}</small>
              </b-table-column>
              <b-table-column field="" label="">
                <button class="button is-small is-danger is-pulled-right" @click="deleteGameplay(props.row.id)">
                  <i class="fas fa-times" />
                </button>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered content">
                  <p>
                    <b-icon
                      custom-class="far"
                      pack="fa"
                      icon="frown"
                      size="is-large"
                    />
                  </p>
                  <p>Nothing here.</p>
                </div>
              </section>
            </template>
          </b-table>

          <b-table
            v-if="beekeeperHistoryData && activeTab === 1"
            :data.sync="beekeeperHistoryData"
            :paginated="true"
            :per-page="10"
            :mobile-cards="true"
            :striped="true"
            :selectable="false"
            :sort="false"
          >
            <template slot-scope="props">
              <b-table-column field="id" :visible="false">
                {{ props.row.id }}
              </b-table-column>
              <b-table-column field="time" label="Time" item-width="10px">
                <p class="small">
                  {{ dateFromServerDate(props.row.timestamp) }}
                </p>
              </b-table-column>
              <b-table-column field="list" label="List">
                <p>{{ props.row.list_title }} <a href="#" @click.prevent="showGameDetail(props.row.id)"><i class="fa fa-info-circle" /></a></p>
                <div :id="'gameDetail' + props.row.id" style="display:none">
                  <a href="#" @click.prevent="showChartFor('list', props.row.list_ident)"><i class="fas fa-chart-line" aria-hidden="true" /> View List Competence</a><br>
                  <ul>
                    <li v-for="(res, index) in props.row.words.split(',')">
                      {{ res }} <a href="#" class="is-pulled-right" @click.prevent="showChartFor('word', res)"><i class="fas fa-chart-line" aria-hidden="true" /></a>
                    </li>
                  </ul>
                </div>
              </b-table-column>
              <b-table-column field="earnings" label="Earnings">
                <small>🍯{{ props.row.earnings }}</small>
              </b-table-column>
              <b-table-column field="" label="">
                <button class="button is-small is-danger is-pulled-right" @click="deleteBeekeeperGameplay(props.row.id)">
                  <i class="fas fa-times" />
                </button>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered content">
                  <p>
                    <b-icon
                      custom-class="far"
                      pack="fa"
                      icon="frown"
                      size="is-large"
                    />
                  </p>
                  <p>Nothing here.</p>
                </div>
              </section>
            </template>
          </b-table>

          <b-table
            v-if="buzzwordsHistoryData && activeTab === 2"
            :data.sync="buzzwordsHistoryData"
            :paginated="true"
            :per-page="10"
            :mobile-cards="true"
            :striped="true"
            :selectable="false"
            :sort="false"
          >
            <template slot-scope="props">
              <b-table-column field="id" :visible="false">
                {{ props.row.id }}
              </b-table-column>
              <b-table-column field="time" label="Time" item-width="10px">
                <p class="small">
                  {{ dateFromServerDate(props.row.timestamp) }}
                </p>
              </b-table-column>
              <b-table-column field="words" label="Words">
                <p>{{ props.row.words.split(',').length }} <a href="#" @click.prevent="showGameDetail(props.row.id)"><i class="fa fa-info-circle" /></a></p>
                <div :id="'gameDetail' + props.row.id" style="display:none">
                  <ul>
                    <li v-for="(res, index) in props.row.words.split(',')">
                      {{ res }} <a href="#" class="is-pulled-right" @click.prevent="showChartFor('word', res)"><i class="fas fa-chart-line" aria-hidden="true" /></a>
                    </li>
                  </ul>
                </div>
              </b-table-column>
              <b-table-column field="difficulty" label="Level">
                <small>{{ props.row.hard === 1 ? 'Hard' : 'Easy' }}</small>
              </b-table-column>
              <b-table-column field="score" label="Score">
                <small>{{ props.row.score.toLocaleString() }}</small>
              </b-table-column>
              <b-table-column field="earnings" label="Earnings">
                <small>🍯{{ props.row.earnings }}</small>
              </b-table-column>
              <b-table-column field="" label="">
                <button class="button is-small is-danger is-pulled-right" @click="deleteBuzzwordsGameplay(props.row.id)">
                  <i class="fas fa-times" />
                </button>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered content">
                  <p>
                    <b-icon
                      custom-class="far"
                      pack="fa"
                      icon="frown"
                      size="is-large"
                    />
                  </p>
                  <p>Nothing here.</p>
                </div>
              </section>
            </template>
          </b-table>
          <b-table
            v-else-if="sentencesData && activeTab === 3"
            :data.sync="sentencesData"
            :paginated="true"
            :per-page="10"
            :mobile-cards="true"
            :striped="true"
            :selectable="false"
            :sort="false"
          >
            <template slot-scope="props">
              <b-table-column field="id" :visible="false">
                {{ props.row.id }}
              </b-table-column>
              <b-table-column field="time" label="Time" item-width="10px">
                <p class="small">
                  {{ dateFromServerDate(props.row.timestamp) }}
                </p>
              </b-table-column>
              <b-table-column field="list" label="List">
                <p>{{ props.row.list_title }} <a href="#" @click.prevent="showGameDetail(props.row.id)"><i class="fa fa-info-circle" /></a></p>
                <div :id="'gameDetail' + props.row.id" style="display:none">
                  <a href="#" @click.prevent="showChartFor('list', props.row.list_ident)"><i class="fas fa-chart-line" aria-hidden="true" /> View List Competence</a><br>
                  <router-link v-if="props.row.hive_id" :to="'/hives/' + props.row.hive_id">
                    <i class="fas fa-sitemap" aria-hidden="true" /> View Hive
                  </router-link>
                  <ul>
                    <li v-for="(res, index) in props.row.resultsData" :key="res.word">
                      {{ res.word }}
                      <i v-if="parseInt(res.correct)" class="mdi mdi-check has-text-success" />
                      <i v-else class="mdi mdi-close has-text-danger" /> <small v-if="!parseInt(res.correct) && res.answerGiven" class="has-text-danger">{{ res.answerGiven }}</small> <a href="#" class="is-pulled-right" @click.prevent="showChartFor('word', res.word)"><i class="fas fa-chart-line" aria-hidden="true" /></a>
                    </li>
                  </ul>
                </div>
              </b-table-column>
              <b-table-column field="difficulty" label="Level">
                <small>{{ props.row.difficulty === 1 ? 'Easy' : props.row.difficulty === 2 ? 'Medium' : props.row.difficulty === 3 ? 'Hard' : 'Extreme' }}</small>
              </b-table-column>
              <b-table-column field="percentage" label="%">
                <small>{{ parseInt(props.row.percentage_score * 100) }}%</small>
              </b-table-column>
              <b-table-column field="score" label="Score">
                <small>{{ props.row.score.toLocaleString() }}</small>
              </b-table-column>
              <b-table-column field="earnings" label="Earnings">
                <small>🍯{{ props.row.earnings }}</small>
              </b-table-column>
              <b-table-column field="" label="">
                <button class="button is-small is-danger is-pulled-right" @click="deleteGameplay(props.row.id)">
                  <i class="fas fa-times" />
                </button>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered content">
                  <p>
                    <b-icon
                      custom-class="far"
                      pack="fa"
                      icon="frown"
                      size="is-large"
                    />
                  </p>
                  <p>Nothing here.</p>
                </div>
              </section>
            </template>
          </b-table>
          <div v-else class="alert">
            <b>{{ response }}</b>
          </div>
          <!-- </div>
          </div> -->
        </div>
        <div v-else class="box-body text-center content">
          <h2>Premium Feature</h2>
          <p>Upgrade to premium to use this feature.</p>
          <router-link v-if="isAdmin" to="/manage-subscription" class="btn button is-link" data-dismiss="modal">
            Upgrade
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import Chartist from 'chartist'
import VueChartist from 'v-chartist'
import Switches from 'vue-switches'
import _ from 'lodash'
import { request } from '@/edshed-common/api'
require('chartist-plugin-legend')

export default {
  name: 'PupilHistory',
  components: {
    VueChartist,
    Chartist,
    Switches
  },
  props: {
    pupil: null
  },
  data (router) {
    return {
      loading: '',
      historyData: null,
      sentencesData: null,
      beekeeperHistoryData: null,
      buzzwordsHistoryData: null,
      chartType: 'list',
      allLists: [],
      allWords: [],
      selectedList: null,
      selectedWord: null,
      aggregate: true,
      response: 'Data Loading...',
      chart: null,
      activeTab: 0
    }
  },
  computed: {
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    }
  },
  watch: {
    aggregate () {
      this.renderChart()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getHistoryData()
      this.getSentencesData()
      this.getBeekeeperData()
      this.getBuzzwordsData()
    })
  },
  methods: {
    setActiveTab (ind) {
      this.activeTab = ind
    },
    deleteGameplay (id) {
      const c = confirm('Are you sure you want to delete this game play record? This cannot be undone.')
      if (c) {
        // do stuff
        request('DELETE', 'users/me/school/pupils/' + this.pupil.username + '/history/' + id, null)
          .then((response) => {
            this.getHistoryData()
            // alert('Success')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    deleteBeekeeperGameplay (id) {
      const c = confirm('Are you sure you want to delete this game play record? This cannot be undone.')
      if (c) {
        // do stuff
        request('DELETE', 'users/me/school/pupils/' + this.pupil.username + '/beekeeperhistory/' + id, null)
          .then((response) => {
            this.getBeekeeperData()
            // alert('Success')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    deleteBuzzwordsGameplay (id) {
      const c = confirm('Are you sure you want to delete this game play record? This cannot be undone.')
      if (c) {
        // do stuff
        request('DELETE', 'users/me/school/pupils/' + this.pupil.username + '/buzzwordshistory/' + id, null)
          .then((response) => {
            this.getBuzzwordsData()
            // alert('Success')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    renderChart () {
      const series = []

      if (this.chartType === 'list') {
        // now we need a series for each word
        // get all words in all plays in case of changes
        let words = []
        for (let i = 0; i < this.historyData.length; i++) {
          for (let j = 0; j < this.historyData[i].resultsData.length; j++) {
            if (this.historyData[i].list_ident === this.selectedList && this.historyData[i].resultsData[j].word && this.historyData[i].resultsData[j].word !== '') {
              words.push(this.historyData[i].resultsData[j].word)
              // console.log('list ident: ' + this.selectedList)
              // console.log(this.historyData[i].list_ident)
              // console.log('adding ' + this.historyData[i].resultsData[j].word)
            }
          }
        }
        words = _.uniq(words)
        // create a series for each word
        const aggSer = { name: 'List score', data: [] }
        for (let k = 0; k < words.length; k++) {
          const dat = this.getChartSeriesDataForWord(words[k])
          // console.log('dat: ' + JSON.stringify(dat))
          if (!this.aggregate) {
            const ser = { name: words[k], data: dat }
            series.push(ser)
          } else {
            let dta = aggSer.data
            dta = dta.concat(dat)
            aggSer.data = dta
          }
        }
        if (this.aggregate) {
          // var dates = []
          const newData = []
          for (let l = 0; l < aggSer.data.length; l++) {
            // dates.push(aggSer.dta.data[l].x)
            const x = moment(aggSer.data[l].x).format('YYYY/MM/DD')
            if (!newData[x]) {
              newData[x] = []
            }
            newData[x].push(aggSer.data[l].y)
          }
          const chartData = []
          for (const key in newData) {
            const d = { x: moment(key, 'YYYY/MM/DD').toDate(), y: newData[key].reduce((total, score) => total + score) / newData[key].length }
            chartData.push(d)
          }
          aggSer.data = chartData.sort((a, b) => a.x - b.x)
          console.log('aggser: ' + JSON.stringify(aggSer))
          series.push(aggSer)
        }
      } else { // word
        // one series
        const dt = this.getChartSeriesDataForWord(this.selectedWord)
        const s = { name: this.selectedWord, data: dt }
        series.push(s)
      }
      // this.chart = null

      this.chart = new Chartist.Line('#chart1', { series },
        {
          axisX: {
            type: Chartist.FixedScaleAxis,
            divisor: 5,
            labelInterpolationFnc (value) {
              return moment(value).format('MMM D')
            }
          },
          fullWidth: true,
          chartPadding: {
            right: 40
          },
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0.1,
            fillHoles: true
          }),
          high: 100,
          low: 0,
          height: 300,
          plugins: [
            Chartist.plugins.legend()
          ]
        })
    },
    getChartSeriesDataForWord (word) {
      const d = []
      for (let i = 0; i < this.historyData.length; i++) {
        for (let j = 0; j < this.historyData[i].resultsData.length; j++) {
          if (this.historyData[i].resultsData[j].word.toLowerCase() === word.toLowerCase()) {
            const date = moment(this.historyData[i].timestamp).format('YYYY/MM/DD')
            const dp = { d: date, y: this.historyData[i].resultsData[j].correct }
            d.push(dp)
          }
        }
      }
      let dtar = []
      for (let k = 0; k < d.length; k++) {
        dtar.push(d[k].d)
      }
      dtar = _.uniq(dtar)

      const dt = []
      for (let l = 0; l < dtar.length; l++) {
        // get count of elements with key by filtering
        const df = _.filter(d, { d: dtar[l] })

        // average by reduce => sum & divide by length
        const len = df.length
        const sum = df.reduce(function (total, num) { return total + parseInt(num.y) }, 0)
        const avg = 100 * sum / len
        // create new el
        const el = { x: moment(dtar[l] + ' 00:00:00', 'YYYY/MM/DD HH:mm:ss').toDate(), y: avg }
        dt.push(el)
      }
      return dt
    },
    showChartFor (type, key) {
      this.chartType = type
      if (type === 'list') {
        this.selectedList = key
      } else {
        this.selectedWord = key
      }
      this.renderChart()
    },
    getHistoryData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/pupils/' + this.pupil.username + '/history', null)
        .then((response) => {
          const data = response.data
          this.historyData = data.data
          this.response = ''

          if (!this.historyData || this.historyData.length === 0) {
            this.response = 'No Groups'
          }

          for (let i = 0; i < this.historyData.length; i++) {
            const resArr = this.historyData[i].results.split(',')
            const resArr3 = []
            for (let j = 0; j < resArr.length; j++) {
              const resArr2 = resArr[j].split(':')
              const row = { word: resArr2[0], correct: resArr2[1], answerGiven: resArr2[2] }
              resArr3.push(row)
            }
            this.historyData[i].resultsData = resArr3
          }
          this.getAllLists()
          this.getAllWords()
          this.selectedList = this.allLists.length > 0 ? this.allLists[0].ident : null
          this.selectedWord = this.allWords[0]
          this.renderChart()
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getSentencesData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/pupils/' + this.pupil.username + '/sentenceshistory', null)
        .then((response) => {
          const data = response.data
          this.sentencesData = data.data
          this.response = ''

          if (!this.sentencesData || this.sentencesData.length === 0) {
            this.response = 'No Groups'
          }

          for (let i = 0; i < this.sentencesData.length; i++) {
            const resArr = this.sentencesData[i].results.split(',')
            const resArr3 = []
            for (let j = 0; j < resArr.length; j++) {
              const resArr2 = resArr[j].split(':')
              const row = { word: resArr2[0], correct: resArr2[1], answerGiven: resArr2[2] }
              resArr3.push(row)
            }
            this.sentencesData[i].resultsData = resArr3
          }
          this.getAllLists()
          this.getAllWords()
          this.selectedList = this.allLists.length > 0 ? this.allLists[0].ident : null
          this.selectedWord = this.allWords[0]
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getBeekeeperData () {
      request('GET', 'users/me/school/pupils/' + this.pupil.username + '/beekeeperhistory', null)
        .then((response) => {
          const data = response.data
          this.beekeeperHistoryData = data.data
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getBuzzwordsData () {
      request('GET', 'users/me/school/pupils/' + this.pupil.username + '/buzzwordshistory', null)
        .then((response) => {
          const data = response.data
          this.buzzwordsHistoryData = data.data
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    dateFromServerDate (dt) {
      if (dt === null || dt === '' || !moment(dt).isValid()) {
        return null
      }
      const m = moment(dt)
      // var utcOffset = new Date().getTimezoneOffset()
      // m.add({minutes: utcOffset})
      const dts = m.toDate().toLocaleDateString() + ' ' + m.format('HH:mm')
      return dts // m.format('DD/MM/YYYY HH:mm')
    },
    getAllLists () {
      const lists = []
      for (let i = 0; i < this.historyData.length; i++) {
        const l = { title: this.historyData[i].list_title, ident: this.historyData[i].list_ident }
        lists.push(l)
      }
      this.allLists = _.sortBy(_.uniqBy(lists, 'ident'), 'title')
    },
    getAllWords () {
      const words = []
      for (let i = 0; i < this.historyData.length; i++) {
        for (let j = 0; j < this.historyData[i].resultsData.length; j++) {
          if (this.historyData[i].resultsData[j].word && this.historyData[i].resultsData[j].word !== '') {
            words.push(this.historyData[i].resultsData[j].word)
          }
        }
      }
      this.allWords = _.orderBy(_.uniqBy(words), [word => word.toLowerCase()], ['asc'])
    },
    showGameDetail (gameId) {
      $('#gameDetail' + gameId).toggle()
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>

<style scoped>
  .form-group {
    padding-right: 10px !important;
  }
</style>
<style lang="scss">
  @import "./node_modules/chartist/dist/scss/settings/_chartist-settings.scss";

  .ct-major-tenth svg {
    top: 20px;
  }
  .ct-legend {
    position: relative;
    z-index: 10;
    list-style: none;
    text-align:center;
    padding:10px 0;

    li {
        position: relative;
        padding-left: 23px;
        margin-right: 10px;
        margin-bottom: 3px;
        cursor: pointer;
        display: inline-block;
    }

    li:before {
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        content: '';
        border: 3px solid transparent;
        border-radius: 2px;
    }

    li.inactive:before {
        background: transparent;
    }

    &.ct-legend-inside {
        position: absolute;
        top: 0;
        right: 0;
    }

    @for $i from 0 to length($ct-series-colors) {
        .ct-series-#{$i}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
        .ct-series-#{$i+length($ct-series-colors)}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
        .ct-series-#{$i+length($ct-series-colors)*2}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
        .ct-series-#{$i+length($ct-series-colors)*3}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
        .ct-series-#{$i+length($ct-series-colors)*4}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
        .ct-series-#{$i+length($ct-series-colors)*5}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
        .ct-series-#{$i+length($ct-series-colors)*6}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
        .ct-series-#{$i+length($ct-series-colors)*7}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
        .ct-series-#{$i+length($ct-series-colors)*8}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
        .ct-series-#{$i+length($ct-series-colors)*9}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
        .ct-series-#{$i+length($ct-series-colors)*10}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
    }
}
</style>
